 import React, { useState, useEffect, useContext } from "react";
import "./FloorPlans.css";
import floorPlanA from "../assets/Earth 1 - Flip Chart - 2023-01-28-36.webp";
import floorPlanB from "../assets/Earth 1 - Flip Chart - 2023-01-28-37.webp";
import floorPlanC from "../assets/Earth 1 - Flip Chart - 2023-01-28-40.webp";
import floorPlanD from "../assets/Earth 1 - Flip Chart - 2023-01-28-41.webp";
import { useLocation, useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { ThanksContext } from "../App";
import godrejlogo from '../assets/godrejlogo.jpg';
 // Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";




const FloorPlans = () => {
  const { ip, setThanksState } = useContext(ThanksContext);
  const navigate = useNavigate();
  //Handeling Form Logic
  //data
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "form_popup",
    ip: "",
    domain: "hillside.godrejofficial.in",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });


  //Setting ip address
  useEffect(() => {
    setEnquiryData((prevData) => ({ ...prevData, ip }));
  }, [ip]);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      // Allow only letters and spaces for the 'name' field
      value = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === "phone") {
      // Allow only numbers for the 'phone' field and limit length to 12 characters
      value = value.replace(/[^0-9]/g, '').substring(0, 10);
    }
  
    setEnquiryData({ ...enquiryData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(enquiryData);
    try {
      const fetchData = async () => {
        const jsonData = JSON.stringify(enquiryData);
        await axios
          .post(
            "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            setThanksState(true);
            navigate("/thanks");
          });
      };
      fetchData();
      // console.log(enquiryData);
    } catch (error) {
      console.log(error.message);
    }
  };

  //utm data
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("utm_source")) {
      setEnquiryData((prevData) => ({
        ...prevData,
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        utm_content: searchParams.get("utm_content"),
      }));
    }
  }, [location]);

  const [formPopup, setFormpopup] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [transitionClass, setTransitionClass] = useState("");

  useEffect(() => {
    if (formPopup) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setTransitionClass("popupEffect");
      }, 10); // Delay adding the class
    } else {
      setTransitionClass(""); // Remove the class immediately
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formPopup]);

  const closePopup = (e) => {
    e.stopPropagation();
    setIsExiting(true);
    setTimeout(() => {
      setFormpopup(false);
      setIsExiting(false);
    }, 300); // Duration of the transition in milliseconds
  };
  //************** */

  //handling slides wrt windowWidth
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [windowWidth, setWindowwidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if(windowWidth<600){
        setSlidesPerView(1)
    }else if (windowWidth < 800) {
      setSlidesPerView(2);
    } else if (windowWidth < 1200) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(4);
    }
  }, [windowWidth]);




  return (
    <div className="floorplan-cont" id="floorplans">
      <h2 className="head">
        Floor Plan
      </h2>
      <div className="floorplanimg-cont">
      <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            className="mySwiper p-1"
        >
          <SwiperSlide>
        <div className="floorplan-img-btn-cont">
          <img src={floorPlanA} alt="" />
          <button onClick={() => setFormpopup(!formPopup)}>View Plan</button>
        </div>
        </SwiperSlide>
          <SwiperSlide>
        <div className="floorplan-img-btn-cont">
          <img src={floorPlanB} alt="" />
          <button onClick={() => setFormpopup(!formPopup)}>View Plan</button>
        </div>
        </SwiperSlide>
          <SwiperSlide>
        <div className="floorplan-img-btn-cont">
          <img src={floorPlanC} alt="" />
          <button onClick={() => setFormpopup(!formPopup)}>View Plan</button>
        </div>
        </SwiperSlide>
          <SwiperSlide>
        <div className="floorplan-img-btn-cont">
          <img src={floorPlanD} alt="" />
          <button onClick={() => setFormpopup(!formPopup)}>View Plan</button>
        </div>
        </SwiperSlide>
          
          </Swiper>
      </div>
      {formPopup && (
          <div
            className={`popupContainer ${isExiting ? "exit" : ""}`}
            onClick={closePopup}
          >
            <div
              className={`popup ${transitionClass}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="popup-img-cont">
                <img src={godrejlogo} alt="" />
              </div>
              <div className="popup-inp-cont">
                <h2>Register to view plan</h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    pattern="[A-Za-z\s]+"
                    title="Please enter a valid name (only letters and spaces allowed)."
                    value={enquiryData.name} 
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    minLength="10"
                    maxLength="12"
                    pattern="[0-9]+"
                    title="Please enter a valid number (only numbers allowed)."
                    value={enquiryData.phone}
                    onChange={handleChange}
                    required
                  />
                  <div className="popup-submit-btn-cont">
                    <button type="submit" className="submit-btn">
                      View Plan
                    </button>
                  </div>
                </form>
                <button className="close-btn" onClick={closePopup}>
                  X
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default FloorPlans;
