import './App.css';
import { createContext, useEffect, useState } from 'react'
import AllHomeComponents from './Components/AllHomeComponents';
import Thanks from './Components/Thanks'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import "./Components/FormPopup.css"

export const ThanksContext= createContext()

//Fetching user ip
const useUserIp= ()=>{
  const [ip, setIp] = useState(null);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get(
          "https://godrejofficial.in/userApi.php"
        );
        const userIp = response.data.ip;
        setIp(userIp);
      } catch (error) {
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  return ip
}

function App() {
const [thanksState, setThanksState]= useState(false)
const ip= useUserIp();

  return (
    <div>
     <BrowserRouter>
    <ThanksContext.Provider value={{thanksState, setThanksState, ip}}>
      <Routes>
        <Route path='/' element={<AllHomeComponents/>}/>
        {/* <Route path="/privacy" element={<PrivacyPolicy/>}/> */}
        <Route path="/thanks" element={<Thanks/>}/>
      </Routes>
      </ThanksContext.Provider>
    </BrowserRouter>
    </div>
  );
}

export default App;
