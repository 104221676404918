import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import spa from '../assets/spa.jpg';
import crick from '../assets/crick.jpg';
import multi from '../assets/multi.jpg';
import gymamei from '../assets/gymamei.jpg';
import party from '../assets/party.jpg';
import "./Amenities.css";

const Amenities = () => {
  const isMobileView = window.innerWidth <= 768; // Check if it's a mobile view

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: isMobileView ? 1 : 3, // Show 1 slide on mobile, 3 on larger screens
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="con" id="amenities">
      <h1 className="head">Amenities</h1>
      <Slider {...settings}>
        <div>
          <img className="imghi" src={spa} alt=" 1" />
          <div className="image-title">Exclusive Spa</div>
        </div>
        <div>
          <img className="imghi" src={crick} alt=" 2"/>
          <div className="image-title">Cricket Net</div>
        </div>
        <div>
          <img className="imghi" src={multi} alt=" 3"/>
          <div className="image-title">Multipurpose Hall</div>
        </div>
        <div>
          <img className="imghi" src={gymamei} alt=" 4"/>
          <div className="image-title">Indoor Gym</div>
        </div>
        <div>
          <img className="imghi" src={party} alt=" 5"/>
          <div className="image-title">Party Lawn</div>
        </div>
      </Slider>
    </div>
  );
};

export default Amenities;
