import React, { useContext, useState ,useEffect } from 'react';
import godrejlogo from '../assets/godrejlogo.jpg';
import axios from "axios";
import { ThanksContext } from "../App";
import "./Navbar.css";

import { AiOutlineBars } from 'react-icons/ai'; // Import the hamburger menu icon
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { ip, setThanksState } = useContext(ThanksContext);  //Handeling Form Logic //data
  const navigate = useNavigate();


  useEffect(() => {
    setEnquiryData((prevData) => ({ ...prevData, ip }));
  }, [ip]);


  //Handeling Navbar toggling icon
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //handeling click event of nav-btn
  const handleNavClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  // popup form 
  const [formPopup, setFormpopup] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [transitionClass, setTransitionClass] = useState("");
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "form_popup",
    ip: "",
    domain: "hillside.godrejofficial.in",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      // Allow only letters and spaces for the 'name' field
      value = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === "phone") {
      // Allow only numbers for the 'phone' field and limit length to 12 characters
      value = value.replace(/[^0-9]/g, '').substring(0, 10);
    }
  
    setEnquiryData({ ...enquiryData, [name]: value });
  };


  const closePopup = (e) => {
    e.stopPropagation();
    setIsExiting(true);
    setTimeout(() => {
      setFormpopup(false);
      setIsExiting(false);
    }, 300); // Duration of the transition in milliseconds
  };

  useEffect(() => {
    if (formPopup) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setTransitionClass("popupEffect");
      }, 10); // Delay adding the class
    } else {
      setTransitionClass(""); // Remove the class immediately
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formPopup]);



  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(enquiryData);
    try {
      const fetchData = async () => {
        const jsonData = JSON.stringify(enquiryData);
        await axios
          .post(
            "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            setThanksState(true);
            navigate("/thanks");
          });
      };
      fetchData();
        // console.log(enquiryData);
    } catch (error) {
      console.log(error.message);
    }
  };



  return (
    <div>
      <nav className="navbar">
        <div className="navbar-left">
          <img src={godrejlogo} alt="Logo" className="logo" />
        </div>
        <div className="navbar-center">
          <ul className={`navbar-list ${isMenuOpen ? 'mobile-menu-open' : ''}`}>
            <li className="navbar-item">
              <a href="#home" className="navbar-link">
                Home
              </a>
            </li>
            <li className="navbar-item">
              <a href="#about" className="navbar-link">
                About
              </a>
            </li>
            <li className="navbar-item">
              <a href="#location" className="navbar-link">
                Location
              </a>
            </li>
            <li className="navbar-item">
              <a href="#bestprice" className="navbar-link">
                Best Price
              </a>
            </li>
            <li className="navbar-item">
              <a href="#amenities" className="navbar-link">
                Amenities
              </a>
            </li>
            <li className="navbar-item">
              <a href="#floorplans" className="navbar-link">
                Floor Plan
              </a>
            </li>
            <li className="navbar-item">
              <a href="#enqury" className="navbar-link">
                Contact Us
              </a>
            </li>
            {/* Enquiry Now button for desktop view */}
            <li className={`enquiry-button-desktop ${isMenuOpen ? 'hidden' : ''}`}>
              <button className="mbz enquiry-button" 
               onClick={() => setFormpopup(!formPopup)}
              >Enquiry Now</button>
            </li>
          </ul>
        </div>
        <div className="navbar-right">
          <button className="mobile-menu-button" onClick={handleNavClick}>
            <AiOutlineBars />
          </button>
        </div>
      </nav>
      

      {formPopup && (
        <div
          className={`popupContainer ${isExiting ? "exit" : ""}`}
          onClick={closePopup}
        >
          <div
            className={`popup ${transitionClass}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="popup-img-cont">
              <img src={godrejlogo} alt="" />
            </div>
            <div className="popup-inp-cont">
              <h2>Register to view price</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  pattern="[A-Za-z\s]+"
                  title="Please enter a valid name (only letters and spaces allowed)."
                  value={enquiryData.name} 
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  minLength="10"
                  maxLength="12"
                  pattern="[0-9]+"
                  title="Please enter a valid number (only numbers allowed)."
                  value={enquiryData.phone}
                  onChange={handleChange}
                  required
                />
                <div className="popup-submit-btn-cont">
                  <button type="submit" className="submit-btn">
                    View Price
                  </button>
                </div>
              </form>
              <button className="close-btn" onClick={closePopup}>
                X
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default Navbar;

