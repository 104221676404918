import React from "react";
import godabout from "../assets/godabout.jpg";
import './About.css'
import mountain from '../assets/mountain.png'
import tree from '../assets/tree.png'

const About = () => {
  return (
    <>
      
      <div className="container" id="about">
      <h1 className="head">About Us</h1>
        <section className="about">
        <div className="about-image">
            <img src={godabout} alt="About Us" />
          </div>
          <div className="about-content">
            <p> <img src={mountain} alt="Mountain Icon" className="icon"/>
               Check out Godrej Hillside in
              Mahalunge, one of the upcoming under-construction housing
              societies in Pune. There are apartments for sale in Godrej
              Hillside. This society will have all basic facilities and
              amenities to suit homebuyer’s needs and requirements. Brought to
              you by Godrej Properties, Godrej Hillside is scheduled for
              possession in Oct, 2024. </p>
              <p> <img src={tree} alt="Mountain Icon" className="icon"/>
              Being a RERA-registered society, the project details and other
              important information is also available on state RERA portal. The
              RERA registration number of this project is P52100022099.
            
              </p>
           
          </div>
         
         
        </section>
      </div>
    </>
  );
};

export default About;
