import React from "react";
import mapp from "../assets/mapp.jpg";
import './About.css'
import yes from '../assets/yes.png'
import "./Location.css"


const Location = () => {
  return (
    <>
      
      <div className="container" id="location">
      <h1 className="head">Location Benefits</h1>
        <section className="about">
         
          <div className="about-content">
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>Hill park with 5000+ trees planted Hi-Tech city approved by PMRDA </p>
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>Easy access to 6 Lane Baner-Mahalunge Road  </p>
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>4 Tier security system for the residence</p>
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>In close proximity to Mahalunge-Maan Hi-Tech city along with Hinjewadi, Baner, Wakad, Aundh and PCMC</p>
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>Access to 8+ hectares of greens</p>
            <p><img src={yes} alt="Mountain Icon" className="yes-icon"/>2,630 SQ. MTR of elevated clubhouse</p>
          </div>
          <div className="about-image">
            <img src={mapp} alt="About Us" />
          </div>
         
        </section>
      </div>
    </>
  );
};

export default Location;
