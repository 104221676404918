import React , { useState, useEffect, useContext }from 'react'
import './Home.css'
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ThanksContext } from "../App";

const Home = () => {
  const { ip, setThanksState } = useContext(ThanksContext);
  const navigate = useNavigate();
    //Handeling Form Logic
  //data
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "footer",
    ip: "",
    domain: "hillside.godrejofficial.in",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });

//Setting ip address
useEffect(() => {
  setEnquiryData((prevData) => ({ ...prevData, ip }));
}, [ip]);


   //utm data
   const location = useLocation();

   useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("utm_source")) {
      setEnquiryData((prevData) => ({
        ...prevData,
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        utm_content: searchParams.get("utm_content"),
      }));
    }
  }, [location]);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      // Allow only letters and spaces for the 'name' field
      value = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === "phone") {
      // Allow only numbers for the 'phone' field and limit length to 12 characters
      value = value.replace(/[^0-9]/g, '').substring(0, 10);
    }
  
    setEnquiryData({ ...enquiryData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(queryParam.get("utm_source"));
    try {
      const fetchData = async () => {
        const jsonData = JSON.stringify(enquiryData);
        await axios
          .post(
            "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            setThanksState(true);
            navigate("/thanks");
          });
      };
      fetchData();
      // console.log(enquiryData)
    } catch (error) {
      console.log(error.message);
    }
  };






  return (
    <div className='hero' id='home'>
     <div className='cloud'>
     <h1 className="heading">Your Last Chance To Live By The Hills <br/> The Hills Are Calling...</h1>
     
     <div className="form-container">
        <form className="form"  onSubmit={handleSubmit}>
          <h2 className='boldb'>DROP AN ENQUIRY</h2>
          <h4 className='move'>TO SCHEDULE A SITE VISIT</h4>
          <div className="form-group">
            
            <input type="text" id="name" name="name" placeholder="Your Name"  pattern="[A-Za-z\s]+"
                    title="Please enter a valid name (only letters and spaces allowed)."
                    onChange={handleChange}
                    value={enquiryData.name} 
                    required/>
          </div>
          <div className="form-group">
           
            <input type="email" id="email" name="email" placeholder="Your Email"  onChange={handleChange} required/>
          </div>
          <div className="form-group">
            
            <input id="number" name="number" placeholder="Contact No."   
                  maxLength="12"
                    minLength="10"
                    pattern="[0-9]+"
                    title="Please enter a valid number (only numbers allowed)."
                    onChange={handleChange}
                    value={enquiryData.phone}
                   required/>
          </div>
          <button className='sub' type="submit">Submit</button>
        </form>
      </div>
     </div>
    </div>
  )
}

export default Home



